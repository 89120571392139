/**
 * Created by szymon.szymczyna on 18/04/2016.
 */
$(document).ready(function(){

    //Disable registration button and change style to suit. This will only be enabled once the email has been validated
    $('#submit_registration').prop('disabled', true);
    $('#submit_registration').css('opacity', '0.5');

    //On leaving input check to see if the email is valid
    $('#email').focusout(function() {
        validateEmailField($('#email').val());
    });

    //Validation for registration form
    $('#submit_registration').click(function(){
        if($('#company').val() == ''){
            alert('empty company')
            return false;
        }
        if($('#position').val() == ''){
            alert('empty position')
            return false;
        }
        if($('#firstName').val() == ''){
            alert('empty firstName')
            return false;
        }
        if($('#lastName').val() == ''){
            alert('empty lastName')
            return false;
        }
        if($('#phoneNumber').val() == ''){
            alert('empty phoneNumber')
            return false;
        }
        if($('#username').val() == ''){
            alert('empty username')
            return false;
        }
        if($('#email').val() == ''){
            alert('empty email')
            return false;
        }else{
            if(!validateEmail($('#email').val())){
                alert('fail');
            }else{
                validateEmailField($('#email').val());
            }
        }
        if($('#password').val() == ''){
            alert('empty password')
            return false;
        }
    });
	
	$("#query").addClass('query_input_query');
    
});
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
var domains = [
    /* Default domains included */
    "aol.com", "att.net", "comcast.net", "facebook.com", "gmail.com", "gmx.com", "googlemail.com",
    "google.com", "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com",
    "live.com", "sbcglobal.net", "verizon.net", "yahoo.com", "yahoo.co.uk",
    /* Other global domains */
    "email.com", "games.com" /* AOL */, "gmx.net", "hush.com", "hushmail.com", "icloud.com", "inbox.com",
    "lavabit.com", "love.com" /* AOL */, "outlook.com", "pobox.com", "rocketmail.com" /* Yahoo */,
    "safe-mail.net", "wow.com" /* AOL */, "ygm.com" /* AOL */, "ymail.com" /* Yahoo */, "zoho.com", "fastmail.fm",
    "yandex.com",
    /* United States ISP domains */
    "bellsouth.net", "charter.net", "comcast.net", "cox.net", "earthlink.net", "juno.com",
    /* British ISP domains */
    "btinternet.com", "virginmedia.com", "blueyonder.co.uk", "freeserve.co.uk", "live.co.uk",
    "ntlworld.com", "o2.co.uk", "orange.net", "sky.com", "talktalk.co.uk", "tiscali.co.uk",
    "virgin.net", "wanadoo.co.uk", "bt.com",
    /* Domains used in Asia */
    "sina.com", "qq.com", "naver.com", "hanmail.net", "daum.net", "nate.com", "yahoo.co.jp", "yahoo.co.kr", "yahoo.co.id", "yahoo.co.in", "yahoo.com.sg", "yahoo.com.ph",
    /* French ISP domains */
    "hotmail.fr", "live.fr", "laposte.net", "yahoo.fr", "wanadoo.fr", "orange.fr", "gmx.fr", "sfr.fr", "neuf.fr", "free.fr",
    /* German ISP domains */
    "gmx.de", "hotmail.de", "live.de", "online.de", "t-online.de" /* T-Mobile */, "web.de", "yahoo.de",
    /* Russian ISP domains */
    "mail.ru", "rambler.ru", "yandex.ru", "ya.ru", "list.ru",
    /* Belgian ISP domains */
    "hotmail.be", "live.be", "skynet.be", "voo.be", "tvcablenet.be", "telenet.be",
    /* Argentinian ISP domains */
    "hotmail.com.ar", "live.com.ar", "yahoo.com.ar", "fibertel.com.ar", "speedy.com.ar", "arnet.com.ar",
    /* Domains used in Mexico */
    "hotmail.com", "gmail.com", "yahoo.com.mx", "live.com.mx", "yahoo.com", "hotmail.es", "live.com", "hotmail.com.mx", "prodigy.net.mx", "msn.com"
];

function validateEmailField(emailValue)
{
    var splitArray = emailValue.split('@'); // To Get Array
    if(domains.indexOf(splitArray[1]) >= 0)
    {
        alert('Only company emails are accepted!');

        //Disable registration button and change style to suit. This will only be enabled once the email has been validated
        $('#submit_registration').prop('disabled', true);
        $('#submit_registration').css('opacity', '0.5');
        return false;
    }

    //If the email is valid then enable button and change button style to suit
    $('#submit_registration').prop('disabled', false);
    $('#submit_registration').css('opacity', '1');

    return true;
}
