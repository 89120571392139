import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'


Bugsnag.start({
  apiKey: process.env.MIX_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
})

require('./navigation.js');
require('./sitewide.js');
