/**
 * Created by szymon.szymczyna on 29/04/2016.
 */
$(document).ready(function(){
    $(".nav_button").click(function() {
        $( ".nav_list" ).toggle();
    });
    var mouse_is_inside = false;
    $(".nav_list").hover(function(){
        mouse_is_inside=true;
    }, function(){
        mouse_is_inside=false;
    });
    $("body").mouseup(function(){
        if(! mouse_is_inside) $('.nav_list').hide();
        if(! mouse_is_inside) $('.twitter_popup').hide();
        if(! mouse_is_inside) $('.twitter_popup_footer').hide();
    });

    $('.back_to_top_button').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: 0
        }, 700);
    });

    $(function () {
        var icons = {
            header: "iconClosed",    // custom icon class
            activeHeader: "iconOpen" // custom icon class
        };

        $( "#accordion" ).accordion({
            heightStyle: "content",
            icons: icons
        });
    });

    $(".footer_query_button").click(function() {
        $(this).addClass("button_active");
        $('.footer_contact_button ').removeClass("button_active");
        $('.query_area').css('display','block');
        $('.get_in_touch_area').css('display','none');
    });

    $(".footer_contact_button").click(function() {
        $(this).addClass("button_active");
        $('.footer_query_button').removeClass("button_active");
        $('.get_in_touch_area').css('display','block');
        $('.query_area').css('display','none');
    });


    $('.nav_mobile_area').height($(document).height());
    var toggle = false;

    $(".nav_mobile").click(function() {
        if (toggle == false) {
            toggle = true;
            $('#content').animate({"left":"270px"}, 500);
            $('.nav_mobile_area a').css('display','block');
            $('.nav_mobile_area a').fadeIn();
            //$('.container').animate({"left":"300px"}, 500);
            //$('.footer_area').animate({"left":"300px"}, 500);
        } else {
            toggle = false;
            $('#content').animate({"left":"0"}, 500);
            //$('.nav_mobile_area a').css('display','none');
            $('.nav_mobile_area a').fadeOut();
            //$('.container').animate({"left":"0"}, 500);
            //$('.footer_area').animate({"left":"0"}, 500);
        }
    });
})


